import { DOCUMENT } from '@angular/common';
import {
  afterNextRender,
  Component,
  HostListener,
  inject,
} from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { WA_NAVIGATOR, WA_WINDOW } from '@ng-web-apis/common';
import { AppStore } from './core/+store/app.store';

declare const tizen: any;
declare const webOS: any;

@Component({
  selector: 'player-root',
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
})
export class AppComponent {
  private readonly appStore = inject(AppStore);
  private readonly document = inject(DOCUMENT);
  private readonly navigator = inject(WA_NAVIGATOR);
  private readonly window = inject(WA_WINDOW);

  constructor() {
    afterNextRender(() => {
      if (this.document.visibilityState === 'visible') {
        this.wakeLock();
      }

      this.unregisterServiceWorker();
    });
  }

  @HostListener('document:visibilitychange')
  async onVisibilityChange() {
    if (this.document.visibilityState === 'visible') {
      await this.wakeLock();
    }
  }

  /**
   * La función `wakeLock` comprueba si el navegador admite wake lock y lo activa para la pantalla si
   * está disponible.
   */
  private async wakeLock() {
    if ('wakeLock' in this.navigator) {
      try {
        await this.navigator.wakeLock.request('screen');
        console.log('Wake lock activated');
      } catch {}
    }
  }

  @HostListener('document:fullscreenchange')
  onFullScreenChange() {
    this.appStore.setIsFullScreen(this.document.fullscreenElement !== null);
  }

  @HostListener('window:tizenhwkey', ['$event'])
  onTizenHWKey(event: any) {
    if (event.keyName === 'back' && typeof tizen !== 'undefined') {
      const page = this.document.querySelector('.ui-page-active');
      const pageid = page?.id ?? '';
      if (pageid === 'main') {
        try {
          tizen.application.getCurrentApplication().exit();
        } catch (ignore) {}
      } else {
        this.window.history.back();
      }
    }
  }

  @HostListener('window:keydown', ['$event'])
  onWebOSBackKey(event: KeyboardEvent) {
    let isBackKey = false;
    if (this.window.event) {
      isBackKey = event.keyCode === 461;
    } else if (event.which) {
      isBackKey = event.which === 461;
    } else {
      isBackKey = event.key === 'Back';
    }

    if (isBackKey && typeof webOS !== 'undefined') {
      if (webOS.platform.tv) {
        webOS.platformBack();
      }
    }
  }

  @HostListener('window:online')
  onOnline() {
    if (!this.appStore.isOnline()) {
      this.appStore.setIsOnline(true);
    }
  }

  @HostListener('window:offline')
  onOffline() {
    if (this.appStore.isOnline()) {
      this.appStore.setIsOnline(false);
    }
  }

  private async unregisterServiceWorker() {
    try {
      if ('serviceWorker' in this.navigator) {
        const registrations =
          await this.navigator.serviceWorker.getRegistrations();

        await Promise.allSettled(
          (registrations ?? []).map((registration) =>
            registration.unregister(),
          ),
        );
      }
    } catch {}
  }
}
